<template>
  <div class="app-container">
    <div id="app-contain">
        <div class="filter-container rz" id="filter-container">
      <el-input v-model="listQuery.name" placeholder="操作者" style="width: 200px;" class="filter-item" clearable />
      <el-date-picker v-model="listQuery.beginDate" type="date" placeholder="操作日期" style="width: 200px;" class="filter-item" value-format="yyyy-MM-dd" />
      <el-button v-waves class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
      <el-button v-waves class="filter-item" type="danger" icon="el-icon-delete" @click="onRemove">
        清除日志
      </el-button>
    </div>
    <el-table :key="tableKey" v-loading="listLoading" :data="list" :height="tabheight" border fit highlight-current-row style="width: 100%;overflow:auto">
      <el-table-column type="index" align="center" label="序号" width="80px" />
      <el-table-column label="操作者" header-align="center" align="left" width="200" prop="userName" />
      <el-table-column label="操作日期" header-align="center" align="right" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="IP" header-align="center" align="right" prop="ipAddr" width="200" />
      <el-table-column label="操作记录" header-align="center" align="left" prop="content" show-overflow-tooltip />
    </el-table>
    <pagination id="pagination" v-show="total>-1" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>

import { getPage, remove } from '@/api/operateLog'
import waves from '@/directive/waves' // waves directive
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import { tableHeight2 } from '@/utils/tableHeight'
import { Message, MessageBox } from 'element-ui'

export default {
  name: 'Dlrz',
  components: { Pagination },
  directives: { waves },
  mixins: [tableHeight2],
  data() {
    return {
      tabheight:0,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        name: undefined,
        beginDate: undefined
      },
      loginTypes: [],
      loginSucs: []
    }
  },
       mounted(){
     this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination
      console.log(this.tabheight,filterHeight,tableHeight,pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination
        })()
      }
    })
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      getPage(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.records
        this.total = parseInt(response.data.total)
      }).catch(response => {
        this.listLoading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    onRemove() {
      if (this.listQuery.beginDate === '' || this.listQuery.beginDate === null || this.listQuery.beginDate === undefined) {
        Message({
          message: '请选择日期',
          type: 'error',
          duration: 2 * 1000
        })
        return
      }
      MessageBox.confirm('确认删除', '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '正在清除......',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        remove({ date: this.listQuery.beginDate }).then(response => {
          Message({
            message: '删除成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getList()
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.app-container{
    height:calc(100% - 20px);
    width:calc(100% - 40px);
    padding:10px 20px;
}
#app-contain{
    height:100%;
    width:100%;
}
.filter-container {

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
    margin-top:0 !important;
    padding: 10px 16px !important;
}
</style>
