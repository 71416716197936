<template>
  <div class="app-container">
    <div id="app-contain">
        <div class="filter-container rz" id="filter-container">
      <el-input v-model="listQuery.loginName" placeholder="登录名" style="width: 150px;" class="filter-item" clearable />
      <el-date-picker v-model="listQuery.beginDate" type="date" placeholder="开始日期" style="width: 150px;" class="filter-item" value-format="yyyy-MM-dd" />
      <el-date-picker v-model="listQuery.endDate" type="date" placeholder="结束日期" style="width: 150px;" class="filter-item" value-format="yyyy-MM-dd" />
      <el-button v-waves class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
    </div>
    <el-table :key="tableKey" v-loading="listLoading" :data="list" :height="tabheight" border fit highlight-current-row style="width: 100%;overflow:auto">
      <el-table-column label="登录名" header-align="center" align="left" prop="loginName" width="200" />
      <el-table-column label="ip" header-align="center" align="right" prop="ipAddr" width="200" />
      <el-table-column label="浏览器" header-align="center" align="left" prop="browser" show-overflow-tooltip />
      <el-table-column label="登录状态" header-align="center" align="left" width="150">
        <template slot-scope="scope">
          <span>{{ getDicName(scope.row.loginSuc,'YW_LOGIN_SUC') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="登录方式" header-align="center" align="left" width="150">
        <template slot-scope="scope">
          <span>{{ getDicName(scope.row.loginType,'YW_LOGIN_TYPE') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="登录时间" header-align="center" align="right" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination id="pagination" v-show="total>-1" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import { getPage } from '@/api/loginLog'
import { getDictListByCode } from '@/api/dict'
import waves from '@/directive/waves' // waves directive
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

export default {
  name: 'Dlrz',
  components: { Pagination },
  directives: { waves },
  data() {
    return {
      tabheight:0,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        loginName: undefined,
        beginDate: undefined,
        endDate: undefined
      },
      loginTypes: [],
      loginSucs: []
    }
  },
       mounted(){
     this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination
      console.log(this.tabheight,filterHeight,tableHeight,pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination
        })()
      }
    })
  },
  created() {
    this.getList()
    this.getDictList('YW_LOGIN_TYPE')
    this.getDictList('YW_LOGIN_SUC')
  },
  methods: {
    getList() {
      this.listLoading = true
      getPage(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.records
        this.total = parseInt(response.data.total)
      }).catch(response => {
        this.listLoading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_LOGIN_TYPE') {
          this.loginTypes = response.data
        } else if (code === 'YW_LOGIN_SUC') {
          this.loginSucs = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_LOGIN_TYPE') {
        dict = this.loginTypes
      } else if (flag === 'YW_LOGIN_SUC') {
        dict = this.loginSucs
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.app-container{
    height:calc(100% - 20px);
    width:calc(100% - 40px);
    padding:10px 20px;
}
#app-contain{
    height:100%;
    width:100%;
}
.filter-container {

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
    margin-top:0 !important;
    padding: 10px 16px !important;
}
</style>
