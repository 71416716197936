<template>
  <div class="zl">
    <div class="topinfo">
      <div class="ellist">
        <div
          class="item"
          v-for="(item, index) in elList"
          :key="index"
          :class="item.id === elIndex ? 'active' : ''"
          @click="elIndex = item.id"
          :title="item.roomName"
        >
          {{ item.roomName }}
        </div>
      </div>
      <div class="monitor-list">
        <div
          class="child"
          :class="activeIndex == index2 ? 'active' : ''"
          v-for="(item2, index2) in monitorList"
          :key="index2"
          @click="monitorChildClick(index2)"
        >
          {{ item2.value }}
        </div>
      </div>
    </div>
    <div class="main">
      <wenshidu v-if="elIndex === 0"></wenshidu>
      <shuiqin v-if="elIndex === 1"></shuiqin>
    </div>
  </div>
</template>

<script>
import wenshidu from "./operateLog";
import shuiqin from "./loginLog";
export default {
  components: {
    wenshidu,
    shuiqin
  },
  data() {
    return {
      projectId: "",
      elList: [
        { 'id':0,'roomName':"操作日志"}, 
        { 'id':1,'roomName':"访问日志"}
      ],
      elIndex: 0,
      monitorIndex: 0,
      activeIndex: 0,
      monitorList: []
    };
  },
  computed: {
    isShowDet() {
      return this.$store.state.isShowDet;
    },
  },
  created() {
    // this.projectId = this.$getCookie("projectId");
    // this.searchEquipmentRoomList();
  },
  methods: {
    // 关闭显示回路详情
    isShowDetHal() {
      this.$store.commit("setIsShowDet", false);
    },
    monitorClick(index) {
      this.$set(this.monitorIndex, index);
    },
    monitorChildClick(index) {
      this.activeIndex = index;
    }
  },
  watch: {
    elIndex(n) {
    this.$cookies.set('roomId', n)
      this.$store.commit("setRoomId", n);
    },
  },
};
</script>

<style lang="less" scoped>
.zl {
    height:100%;width:100%; 
  position: relative;
  .topinfo {
    background: #fff;
    padding-bottom: 9px;
   width: calc(100% - 40px);
    padding: 20px 20px 0px 20px;
    box-shadow: 0px 0px 13px rgba(32, 47, 79, 0.09);
    .ellist {
      display: flex;
      margin-top: 0px;
      .item {
        width: 106px;
        height: 32px;
        margin-right: 20px;
        background: #eeeeee;
        border-radius: 5px;
        text-align: center;
        font-size: 12px;
        color: #8e9fa8;
        line-height: 32px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.active {
          color: #fff;
          background: #1460e0;
        }
      }
    }
    .monitor-list {
      display: flex;
      margin-top: 16px;
      .child {
        font-size: 12px;
        line-height: 20px;
        padding: 8px 22px;
        color: #0e223d;
        background: #fff;
        cursor: pointer;
      }
      .child.active {
        background: #fff;
        color: #1460e0;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 22px;
          right: 22px;
          height: 2px;
          background: #1460e0;
        }
      }
    }
  }
  .main {
    height:calc(100% - 68px);
    width:100%
  }
}
</style>