import request from '@/utils/request'

export function getPage(query) {
  return request({
    url: '/ltcloud/operateLog/getPage',
    method: 'get',
    params: query
  })
}

export function remove(data) {
  return request({
    url: '/ltcloud/operateLog/delete',
    method: 'post',
    params: data
  })
}

